@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Lato:regular,bold,light&subset=latin,latin-ext');

body {
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100vh;
  justify-content: space-between;
}

.rs-picker-menu {
  z-index: 1000 !important;
}

.reverse-label .ant-checkbox-input > label {
  order: 2 !important;
}

.reverse-label .ant-form-item-label {
  order: 1 !important;
  margin-left: 6px !important;
}

.desktop {
  display: none;
}
.icon-container {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%) rotate(45deg);
  background-color: #fff;
  border-radius: 12px;
  width: 50px;
  height: 50px;
  z-index: -1;
}
.icon-container svg {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 25px;
  height: 25px;
  z-index: 0;
}

.ant-card .ant-card-head-title {
  width: 85% !important;
  display: inline-block;
  flex: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-card .ant-card-head {
  padding: 0 15px !important;
}

.ant-list-bordered .ant-list-item {
  padding-inline: 15px;
}

@media (min-width: 768px) {
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
  .icon-container {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -10%) rotate(45deg);
    width: 130px;
    height: 130px;
    border-radius: 30px;
  }
  .icon-container svg {
    width: 60px;
    height: 60px;
  }
  .item p {
    max-width: 340px;
    margin: 25px auto;
  }
}

#CybotCookiebotDialogPoweredbyImage {
  max-height: none;
  width: auto;
}

#CybotCookiebotDialog fieldset #CybotCookiebotDialogBodyFieldsetInnerContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  display: none;
}

@media screen and (min-width: 1280px) {
  #CybotCookiebotDialogPoweredbyImage {
    max-height: none;
  }
}
