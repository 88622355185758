.fade-enter {
  opacity: 0.01;
  transform: translateY(-10px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 100ms ease-in, transform 100ms ease-in;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0.01;
  transform: translateY(-10px);
  transition: opacity 100ms ease-in, transform 100ms ease-in;
}
